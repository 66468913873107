<template>
    <Modal v-model="showForm" title="二级分类" :mask-closable="false" width="800">
        <Form ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="90">
            <div class="divider">一级分类</div>
            <FormItem label="分类名称">{{ name }}</FormItem>
            <div class="divider">二级分类</div>
            <div class="filter">
                <Form ref="filter" label-colon inline>
                    <FormItem>
                        <Input v-model="filter.keywords" placeholder="请输入二级分类名称" clearable/>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" @click="doSearching">搜索</Button>
                    </FormItem>
                    <FormItem>
                        <Button type="primary" icon="md-add" @click="handleAdd">新增</Button>
                    </FormItem>
                </Form>
            </div>
            <div class="table">
                <div class="table-list">
                    <Table stripe :columns="secondColumns" :data="copyList" :loading="secondLoading">
                        <template slot-scope="{ row, index }" slot="action">
                            <a @click="handleView(row, index)">详情</a>
                            <Divider type="vertical"/>
                            <a @click="handleEdit(row, index)">编辑</a>
                            <Divider type="vertical"/>
                            <a class="table-delete" @click="handleDel(row, index)">刪除</a>
                        </template>
                    </Table>
                </div>
            </div>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
        <add-second v-model="showAddModal" v-on:closeAddSecondList="closeAddSecondList"></add-second>
        <edit-second v-model="showEditModal" :data="data" v-on:closeEditSecondList="closeEditSecondList"></edit-second>
        <view-second v-model="showViewModal" :data="data"></view-second>
    </Modal>
</template>
<script>
import {reqDelSecond, reqGetSecondList, reqUpdateSecondClass} from "../../../api/admin-api";
import addSecond from "./secondAdd.vue"
import editSecond from "./secondEdit.vue"
import viewSecond from "./secondView.vue"

export default {
    name: "secondList",
    components: {
        addSecond,
        editSecond,
        viewSecond
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
        name: '',
    },
    data() {
        return {
            pid: '',
            data: {},
            filter: {
                keywords: ''
            },
            showAddModal: false,
            showEditModal: false,
            showViewModal: false,
            loading: false,
            showForm: this.value,
            viewForm: {},
            viewFormRule: {},
            secondList: [],
            copyList: [],
            delList: [],
            updateSecondList: [],
            secondLoading: false,
            secondColumns: [
                {
                    title: '分类名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '序号',
                    key: 'sn',
                    width: 80,
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150,
                    align: 'center',
                    fixed: 'right',
                }
            ]
        }
    },
    methods: {
        getSecondList() {
            this.secondLoading = true
            reqGetSecondList({id:this.id , keywords: this.filter.keywords}).then((res)=>{
                this.secondLoading = false
                if (res.data.code === 1){
                    this.secondList = res.data.data;
                    this.copyList = this.secondList;
                }else {
                    this.secondList = []
                }
            }).catch(()=>{
                this.secondLoading = false
                this.secondList = []
            })
        },
        closeEditSecondList(val) {
            this.secondList.splice(val.index, 1)
            if (val.createTime !== undefined){
                this.secondList.push({
                    id: val.id,
                    pid: this.id,
                    name: val.name,
                    sn: val.sn,
                    currentIconName: val.currentIconName,
                    orignalIconName: val.orignalIconName,
                    createBy: val.createBy,
                    createTime: val.createTime,
                })
            }else {
                this.secondList.push({
                    id: val.id,
                    pid: this.id,
                    name: val.name,
                    sn: val.sn,
                    currentIconName: val.currentIconName,
                    orignalIconName: val.orignalIconName,
                })
            }
            this.copyList = this.secondList;
        },
        closeAddSecondList(val) {
            this.secondList.push({
                pid: this.id,
                name: val.name,
                sn: val.sn,
                currentIconName: val.currentIconName,
                orignalIconName: val.orignalIconName
            })
            this.copyList = this.secondList;
        },
        handleSubmit() {
            this.loading = true
            this.updateSecondList = []
            for (let i = 0; i < this.secondList.length; i++) {
                if (!this.secondList[i].updatedByName){
                    this.updateSecondList.push(this.secondList[i])
                }
            }
            let addForm = {}
            addForm.delList = this.delList
            addForm.updateSecondList = this.updateSecondList
            reqUpdateSecondClass(addForm).then((res)=>{
                this.loading = false;
                if (res.data.code === 1){
                    this.$Message.success('添加成功');
                    this.$emit('getWcClassify');
                    this.closeModal(false);
                }else {
                    this.$Message.error('添加失败');
                }
            }).catch(()=>{
                this.loading = false;
                this.$Message.error('添加失败');
            })
        },
        doSearching() {
            if (this.filter.keywords) {
                let myData = JSON.parse(JSON.stringify(this.secondList));
                this.copyList = myData.filter(item => (item.name).includes(this.filter.keywords));
            } else {
                this.copyList = JSON.parse(JSON.stringify(this.secondList));
            }
        },
        handleAdd() {
            this.showAddModal = true
        },
        handleEdit(row, index) {
            this.data = row
            this.data.index = index
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.data = row
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除二级分类',
                content: '确定删除二级分类：'+row.name+'？此操作不可逆。',
                width: '320',
                onOk: () => {
                    if (row.id){
                        this.delList.push(row.id)
                    }
                    this.secondList.splice(index, 1)
                    this.copyList = this.secondList
                },
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.filter.keywords = ''
                this.updateSecondList = []
                this.delList= []
                this.copyList = []
                this.getSecondList();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = {Authorization: token}
    }
}
</script>
