<template>
    <Modal v-model="showForm" title="查看二级分类" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <FormItem label="分类序号">{{ viewForm.sn }}</FormItem>
            <FormItem label="分类名称">{{ viewForm.name }}</FormItem>
            <FormItem label="分类图标">
                <div class="upload-image-list" v-if="imgData !== null">
                    <img :src="imgData">
                    <div class="upload-image-list-cover">
                        <Icon type="ios-eye" @click.native="handlePreview(imgData)"></Icon>
                    </div>
                </div>
            </FormItem>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="320">
            <img :src="imgData" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {SERVER_HOST} from "@/common/portConfig";

export default {
    name: "viewSecond",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {},
    },
    data() {
        return {
            showPreview: false,
            imgData: null,
            loading: false,
            showForm: this.value,
            deleteName: [],
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        handlePreview(val) {
            this.showPreview = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.viewForm.name = this.data.name
                this.viewForm.sn = this.data.sn
                this.imgData = SERVER_HOST + '/resource/public/' + this.data.currentIconName
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
