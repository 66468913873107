<template>
    <Modal v-model="showForm" title="查看分类" :mask-closable="false" width="800px">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">一级分类</div>
            <FormItem label="分类序号">{{ data.sn }}</FormItem>
            <FormItem label="分类名称">{{ data.name }}</FormItem>
            <FormItem label="更新人">{{ data.updatedByName }}</FormItem>
            <FormItem label="更新时间">{{ data.updatedTime }}</FormItem>
            <div class="divider">二级分类</div>
            <Table stripe :columns="secondColumns" :data="secondList" :loading="secondLoading">
                <template slot-scope="{ row, index }" slot="action">
                    <a @click="handleView(row, index)">详情</a>
                </template>
            </Table>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
        <view-second v-model="showViewModal" :data="viewData"></view-second>
    </Modal>
</template>
<script>
import {reqGetSecondList} from "../../../api/admin-api";
import viewSecond from "./secondView.vue"

export default {
    name: "viewParentClass",
    components: {
        viewSecond
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {},
    },
    data() {
        return {
            pid: '',
            viewData: {},
            loading: false,
            showViewModal: false,
            secondList: [],
            secondLoading: false,
            secondColumns: [
                {
                    title: '分类名称',
                    key: 'name',
                    tooltip: 'true',
                    minWidth: 200,
                },
                {
                    title: '更新人',
                    key: 'updatedByName',
                    tooltip: 'true',
                    width: 120,
                },
                {
                    title: '更新时间',
                    key: 'updatedTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120,
                    align: 'center',
                    fixed: 'right',
                }
            ],
            showForm: this.value,
            deleteName: [],
            viewForm: {},
            viewFormRule: {},
        }
    },
    methods: {
        getSecondList() {
            this.secondLoading = true
            reqGetSecondList({id:this.data.id , keywords: ''}).then((res)=>{
                this.secondLoading = false
                if (res.data.code === 1){
                    this.secondList = res.data.data;
                }else {
                    this.secondList = []
                }
            }).catch(()=>{
                this.secondLoading = false
                this.secondList = []
            })
        },
        handleView(row,index) {
            this.viewData = row
            this.showViewModal = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.imgData = null
                this.getSecondList()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
