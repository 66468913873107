<template>
    <Modal v-model="showForm" title="新增一级分类" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="80">
            <FormItem label="分类名称" prop="name">
                <Input type="text" :maxlength="25" v-model="addForm.name" placeholder="请输入分类名称"/>
            </FormItem>
            <FormItem label="分类序号" prop="sn">
                <Input type="text" @on-keyup="recommend($event)" :maxlength="11" v-model="addForm.sn" placeholder="请输入序号"/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqAddParentClass} from "../../../api/admin-api";

export default {
    name: "addParentClass",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            addForm: {
                name: '',
                sn: null,
            },
            addFormRule: {
                name: [{required: true, message: '请输入分类名称', trigger: 'blur'}],
                sn: [{required: true, message: '请输入分类序号', trigger: 'blur'}]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['addForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                reqAddParentClass(this.addForm).then((res)=>{
                    this.loading = false;
                    if (res.data.code === 1){
                        this.$Message.success('添加成功');
                        this.$emit('getWcClassify');
                        this.closeModal(false);
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('添加失败');
                })
            });
        },
        recommend($event) {
            if ('undefined' !== typeof $event) {
                this.addForm.sn = this.addForm.sn.replace(
                    /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                    '$1'
                )
            }
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
    }
}
</script>
